<template>
  <!-- 查看配置 -->
  <div>
    <!-- 填写表单 -->
    <el-form ref="ruleForm" :model="ruleForm" label-width="180px" :rules="rules">
      <el-form-item label="频道名称" prop="name">
        <el-input v-model="ruleForm.name" placeholder="请输入频道名称" :disabled="true" size="mini" style="width: 300px"></el-input>
      </el-form-item>
      <el-form-item label="是否启用">
        <el-radio-group v-model="ruleForm.whetherNot" :disabled="true">
          <el-radio :label="1">关闭</el-radio>
          <el-radio :label="2">启用</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="序号" prop="serialNumber">
        <el-input v-model="ruleForm.sort" :disabled="true" placeholder="请输入标题" size="mini" style="width: 300px"></el-input>
      </el-form-item>
      <el-form-item label="备注">
        <el-input
          v-model="ruleForm.remark"
          :disabled="true"
          type="textarea"
          placeholder="请输入标题"
          :rows="4"
          style="width: 600px"
        ></el-input>
      </el-form-item>
      <el-form-item label="">
        <div>
          <el-button type="" @click="getBack">返回</el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ruleForm: {
        name: '', // 频道名称
        whetherNot: '', // 是否启用
        serialNumber: '', // 序号
        remarks: 0, // 备注
        id: ''
      },
      supList: [],
      //   验证规则
      rules: {
        name: [{ required: true, message: '请输入频道名称', trigger: 'blur' }],
        serialNumber: [{ required: true, message: '请输入序号', trigger: 'blur' }]
      }
    }
  },
  created() {
    this.supportList(this.$route.query.id)
  },
  methods: {
    // 获取所有频道
    supportList(id) {
      this.axios
        .get(`/admin/channel/findById?id=${id}`, {})
        .then(res => {
          console.log(res)
          this.ruleForm = res.data.data
          console.log(this.ruleForm)
        })
        .catch(err => {})
    },
    //   返回上一页面
    getBack() {
      this.$router.go(-1)
    },
    // 保存
    submitForm(formName) {
      console.log(this.ruleForm)
      this.$refs[formName].validate(valid => {
        if (valid) {
          alert('submit!')
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
